<template>
  <v-modal title="Selection d'images">
    <div class="modal-body upload-image-body d-flex flex-wrap m-auto">
      <file-image
        v-for="image in productPhotos"
        :key="image.fileId"
        class="cursor-pointer mr-3 mb-3"
        :class="{'border border-primary shadow': selectedImage && selectedImage.fileId === image.fileId}"
        :src="getUrl(image.file)"
        size="sm"
        @click="selectedImage = image"
      />
      <file-upload-button variant="square" size="sm" icon="plus" :hide-text="true" @click="(files) => handleUpload(files, true)"/>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="$emit('cancel')">Annuler</button>
      <button type="button" class="btn btn-primary" @click="handleConfirm" :disabled="!selectedImage">Appliquer</button>
    </div>
  </v-modal>
</template>

<script>
import FileImage from '@/components/common/FileImage'
import FileUploadButton from '@/components/common/FileUploadButton'
import productImages from '@/mixins/productPhotos'

export default {
  mixins: [productImages],
  components: { FileImage, FileUploadButton },
  data () {
    return {
      selectedImage: this.productImage
    }
  },
  props: {
    productImage: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleConfirm () {
      this.$emit('confirm', this.selectedImage)
    }
  }
}
</script>

<style scoped lang="scss">
.upload-image-body {
  max-height: 450px;
  overflow-y: auto;
}
</style>
